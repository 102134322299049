
  import {
    add_pdf,
    re_gen_invoice_pdf,
    edit_invoice,
    assign_invoice_to_me,
    get_invoice_item,
    get_invoice_link_bill_list,
    link_bill_files_for_invoice,
    link_bill_for_invoice,
    get_link_file_list,
  } from '@/api/erp-invoice';
  import { get_contract_list } from '@/api/erp-contracts';
  import PrintInvoice from '@/components/WePrint/PrintInvoice.vue';
  import InvoicePdfDialog from './components/InvoicePdfDialog.vue';
  import { BasicColumn, BasicTableProps } from '@/components/WeTable';
  import { formatMoneyByRound } from '@/utils/number';
  import { formatTime } from '@/utils/time';
  import { useApp } from '@/hooks/useApp';
  import { useAppInfo } from '@/hooks/useAppInfo';
  import { useUser } from '@/hooks/useUser';
  import { appStore } from '@/store/modules/app';
  import { round } from 'lodash-es';
  import { isEmptyData } from '@/utils/common';
  import { defineComponent, ref } from 'vue';
  import { useInvoice } from './hooks/useInvoice';
  import { DialogFormItem } from '@/components/WeDialog/types';
  import { required, isNumber, isInt } from '@/utils/validate';
  import { useJump } from '@/hooks/useJump';
  import { generatorPDFByCanvas } from '@/utils/pdf';
  import { isDev } from '@/utils/const';
  import { handle_flow_action } from '@/api/crm-customerTrack';

  export default defineComponent({
    name: 'ERPInvoiceDetail',
    components: { PrintInvoice, InvoicePdfDialog },
    props: {
      invoiceId: {
        type: [String, Number],
        default: '',
      },
    },
    setup() {
      const { isDetailPage } = useApp();
      const showPrintInvoice = ref(false);
      const fileMode = ref('');
      const printInvoiceDesc = ref('');
      const neutralizeInvoiceReason = ref('');
      const companyName = ref('');
      const isGts = ref(2);
      const showBuline = ref(false);
      const logic_type = ref(0);
      const { user, isFinance, isAdmin, isTax, isAccountant, isGod } = useUser();
      const { model, currencyMap, userMap, currencyList, appInfo } = useAppInfo();
      const { editInvoice, deleteInvoice } = useInvoice();
      const { jumpToBill, jumpToCompany, jumpToPay } = useJump();
      const invoice = ref<Recordable>({});
      const linkFiles = ref<Recordable[]>([]);
      const InvoiceBillList = ref<Recordable[]>([]);
      const BillSelectedRowKeys = ref<number[]>([]);
      const BillFileSelectedRowKeys = ref<number[]>([]);
      const linkForm = ref<Nullable<FormActionType>>(null);
      return {
        invoice,
        model,
        fileMode,
        currencyMap,
        currencyList,
        appInfo,
        userMap,
        user,
        isFinance,
        isAdmin,
        isTax,
        isGod,
        isAccountant,
        linkFiles,
        InvoiceBillList,
        linkForm,
        isDetailPage,
        editInvoice,
        deleteInvoice,
        BillSelectedRowKeys,
        BillFileSelectedRowKeys,
        jumpToBill,
        jumpToPay,
        jumpToCompany,
        showPrintInvoice,
        printInvoiceDesc,
        companyName,
        isGts,
        showBuline,
        neutralizeInvoiceReason,
        logic_type,
      };
    },
    data: () => ({
      invoiceDetailLoading: false,
      linkBillDialog: false,
      linkBillCheckAll: false,
      linkBillUnlinked: 0,
      linkBillAllLinked: 0,
      linkBillFilesDialog: false,
      invoiceLinkId: null,
      readOnly: false,
    }),
    created() {
      this.getInvoiceDetail(Number(this.invoiceId));
    },
    computed: {
      showInvoice(): boolean {
        return !isEmptyData(this.invoice);
      },
      isSupplyPage(): boolean {
        return this.invoice.invoice_type === this.model.Invoice.TYPE.SUPPLY && !this.invoice.is_purchase;
      },
      isDemandPage(): boolean {
        return this.invoice.invoice_type === this.model.Invoice.TYPE.DEMAND;
      },
      isPurchasePage(): boolean {
        return this.invoice.is_purchase;
      },
      canAssignToMe(): Boolean {
        let res = false;
        if (this.invoice.flow_id) {
          const state = this.invoice.flow!.current_state;
          res = state.owner.id !== this.user.id && !this.invoice.is_canceled && this.isFinance;
        }

        return res;
      },
      pageConfig(): any {
        const temp = this.isSupplyPage
          ? {
              pathSuffix: 'supply',
              title: '业务成本发票',
              customer: '销售方',
              customerCategory: 'Supply',
              type: this.model.Invoice.TYPE.SUPPLY,
            }
          : this.isDemandPage
          ? {
              pathSuffix: 'demand',
              title: '销售发票',
              customer: '客户',
              customerCategory: 'Demand',
              type: this.model.Invoice.TYPE.DEMAND,
            }
          : {
              pathSuffix: 'purchase',
              title: 'Purchase Invoices',
              customer: '销售方',
              customerCategory: 'Purchase',
              type: this.model.Invoice.TYPE.SUPPLY,
            };
        return temp;
      },
      configLinkedBillList(): BasicTableProps {
        const config: BasicTableProps = {
          useSearchForm: false,
          title: '账单',
          immediate: false,
          columns: [
            { title: '团队', dataIndex: 'rd_team_group_name' },
            { title: '部门', dataIndex: 'rd_team_short_name' },
            { title: '归属人', dataIndex: 'rd_owner_name' },
            {
              title: '名称',
              dataIndex: 'name',
              slots: {
                customRender: 'name',
              },
            },
            {
              title: '状态',
              dataIndex: 'rd_state_name',
              colorField: 'rd_state_color',
              slots: {
                customRender: 'rd_state_name',
              },
            },
            { title: '发票金额', dataIndex: 'finance_invoice_money', round: 2 },
            { title: '已关联', dataIndex: 'all_linked_amount', round: 2 },
            { title: '未关联', dataIndex: 'unlinked_amount', round: 2 },
            { title: '此账单关联', dataIndex: 'this_linked_amount', round: 2 },
            {
              title: '操作',
              flag: 'ACTION',
              slots: {
                customRender: 'link',
              },
            },
          ],
        };
        return config;
      },
      get_drop_down_menus(): Recordable[] {
        const ret: Recordable[] = [
          {
            show: this.isAdmin || isDev,
            label: '编辑',
            onClick: () => this.editInvoice(this, 'edit', this.pageConfig, this.invoice),
          },
          {
            show: this.isGod,
            label: '重新生成发票',
            onClick: this.reGenPdf,
          },
          {
            show: this.isAdmin || this.isFinance || isDev,
            label: '修改记录',
            onClick: () => this.$showChange(this.invoice, 'invoice'),
          },
          {
            show: this.isGod,
            label: '删除',
            onClick: () => this.deleteInvoice(this),
          },
        ];
        if (this.moreActions.length > 0) {
          this.moreActions.forEach((item) => {
            ret.push({
              label: item.text,
              onClick: item.click,
              show: item.show,
            });
          });
        }
        return ret.filter((x) => x.show);
      },
      moreActions(): Recordable[] {
        const vm = this;
        if (isEmptyData(this.invoice)) {
          return [];
        }
        const ret = [
          { show: vm.canAssignToMe, text: '认领', click: () => vm.assignToMe() },
          // { show: vm.isFinance || vm.isAdmin || isDev, text: '删除', click: () => vm.deleteInvoice() },
        ];
        return ret.filter((x) => x.show);
      },
      InvoiceBillListColumns(): BasicColumn[] {
        const ret: BasicColumn[] = [
          {
            title: '账单名称',
            key: 'name',
            dataIndex: 'name',
            slots: { customRender: 'name' },
          },
          {
            title: '发票金额',
            key: 'finance_invoice_money',
            dataIndex: 'finance_invoice_money',
            customRender: (text) => {
              return formatMoneyByRound(text.text);
            },
          },
          {
            title: '未关联金额',
            key: 'unlinked_amount',
            dataIndex: 'unlinked_amount',
            customRender: (text) => {
              return formatMoneyByRound(text.text);
            },
          },
          {
            title: 'Link Part',
            key: 'linked_amount',
            dataIndex: 'linked_amount',
            slots: {
              customRender: 'linked_amount',
            },
          },
        ];
        return ret;
      },
      linkFilesColumns(): BasicColumn[] {
        const ret: BasicColumn[] = [
          {
            title: '文件名',
            key: 'file_name',
            dataIndex: 'file_name',
            slots: { customRender: 'file_name' },
          },
        ];
        return ret;
      },
    },
    methods: {
      formatMoneyByRound,
      formatTime,
      isEmptyData,
      handle_save_pdf(res) {
        const vm = this;
        vm.doAfterEditInvoice(res);
        vm.showPrintInvoice = false;
        vm.neutralizeInvoiceReason = '';
      },
      reGenPdf() {
        const vm = this;
        vm.$showAlert('Warning', '确定重新生成发票PDF？', () => {
          re_gen_invoice_pdf({ invoice_id: vm.invoice.id }).then((res) => {
            vm.doAfterEditInvoice(res);
          });
        });
      },
      async showInvoicePdfDialog() {
        const vm = this;
        let logic_type_items = (
          await get_contract_list({
            get_all: true,
            company_id: this.invoice.company_id,
            we_company_id: this.invoice.we_company_id,
            pick_fields: ['name', 'logic_type'],
          })
        ).contract_list.map((x) => ({
          text: x.name,
          value: x.logic_type,
        }));
        this.$showDialog({
          width: '400px',
          labelCol: '100px',
          title: '添加开票描述',
          blocks: [
            {
              type: 'input',
              value: [
                ...(this.invoice.is_prepay && this.invoice.linked_bill_list.length === 0
                  ? [
                      {
                        label: '合同',
                        type: 'select',
                        result: 'logic_type',
                        items: logic_type_items,
                        width: 24,
                      },
                    ]
                  : []),
                {
                  label: '显示媒体名称',
                  type: 'radio',
                  result: 'showBuline',
                  init: false,
                  items: [
                    { text: '是', value: true, label: '是' },
                    { text: '否', value: false, label: '否' },
                  ],
                  width: 24,
                },
              ],
            },
            {
              type: 'input',
              value: [
                {
                  label: '对方开票主体',
                  type: 'text',
                  result: 'company_name',
                  init: this.invoice.company_name,
                  width: 24,
                },
              ],
            },
          ],
          callback: (result) => {
            appStore.SET_WE_DIALOG(null);
            this.showBuline = result.showBuline;
            this.logic_type = result.logic_type;
            this.companyName = result.company_name || '';
            (vm.$refs.InvoicePdfDialog as any).show_dialog();
          },
        });
      },
      async getInvoiceDetail(id: number) {
        this.invoiceDetailLoading = true;
        await get_invoice_item({ invoice_id: id }).then((res) => {
          res.cs_bank = res.cs_bank ? res.cs_bank : Object();
          res.currency = this.currencyMap[res.currency_id];
          res.type = res.is_purchase ? 'Purchase' : this.model.Invoice.TYPE[res.invoice_type];
          this.invoice = res;
          this.invoiceDetailLoading = false;
          document.title = this.invoice.name!;
        });
      },
      async catchFlowFlag(flag, params) {
        if (flag === 'FLAG_EDIT_INVOICE') {
          this.editInvoice(this, 'edit', this.pageConfig, this.invoice);
        } else if (flag === 'FLAG_LINK_BILL') {
          this.openLinkDialog();
        } else if (flag === 'FLAG_EDIT_INVOICE_NUMBER') {
          this.addInvoiceNumber(this.invoice);
        } else if (flag === 'FLAG_MAKE_INVOICE') {
          this.showInvoicePdfDialog();
        } else if (flag === 'FLAG_NEUTRALIZE_INVOICE') {
          if (this.invoice.is_china || this.invoice.is_canceled) {
            const dialogConfig = {
              title: '提示',
              width: '600px',
              blocks: [{ type: 'tip', value: '确定继续操作?' }],
              callback: () => {
                handle_flow_action({ ...params }).then((res) => {
                  if (res.error_message) {
                    this.$showAlert('提示', res.error_message);
                  } else {
                    appStore.SET_WE_DIALOG(null);
                    this.doAfterEditInvoice(res);
                  }
                });
              },
            };
            this.$showDialog(dialogConfig);
          } else {
            this.neutralizeInvoice(params);
          }
        }
      },
      doAfterEditInvoice(res) {
        if (res === 'delete') {
          appStore.SET_DRAWER_CONFIG(null);
          this.$router.go(-1);
        } else if (this.invoice) {
          this.getInvoiceDetail(Number(this.invoiceId));
        }
      },
      canLinkFile(bill) {
        return (
          !(
            this.invoice.is_purchase ||
            this.invoice.is_finished === true ||
            this.invoice.is_canceled === true ||
            bill.is_deleted === true
          ) &&
          (this.isFinance || this.isGod)
        );
      },
      async openLinkDialog() {
        await this.initLinkBill();
        if (this.InvoiceBillList.length > 0) {
          this.linkBillDialog = true;
        } else {
          this.$showAlert('Warning', '无数据');
        }
      },
      linkMoneyChanged(bill) {
        let linked_amount = bill.linked_amount;
        if (bill.linked_amount.startsWith('-')) {
          linked_amount = -Number(bill.linked_amount.replaceAll('-', ''));
        } else if (isNaN(Number(linked_amount))) {
          bill.linked_amount = Number(bill.linked_amount.substr(0, linked_amount.length - 1));
        } else {
          linked_amount = Number(linked_amount);
        }
        this.linkBillUnlinked = round(
          this.linkBillUnlinked + bill.all_amount_can_link - bill.unlinked_amount - linked_amount,
          2
        );
        bill.unlinked_amount = round(bill.all_amount_can_link - linked_amount, 2);
      },
      async initLinkBill() {
        this.linkBillUnlinked = this.invoice.it_amount - parseFloat(this.invoice.linked_amount);
        await get_invoice_link_bill_list({ invoice_id: this.invoice.id }).then((res) => {
          this.InvoiceBillList = res.bill_list.filter((bill) => !!bill.unlinked_amount || !!bill.linked_amount);
          this.BillSelectedRowKeys = [];
          this.InvoiceBillList.forEach((bill, index) => {
            if (bill.is_checked) {
              this.BillSelectedRowKeys.push(index);
            }
          });
        });
      },
      linkBillChange(bill, selected) {
        if (selected) {
          bill.linked_amount = 0;
        } else {
          bill.unlinked_amount = bill.unlinked_amount + bill.linked_amount;
          this.linkBillUnlinked += bill.linked_amount;
          bill.linked_amount = null;
        }
      },
      linkBillCheckAllChange(val) {
        this.BillSelectedRowKeys = val;
        if (val.length === 0) {
          this.InvoiceBillList.filter((x) => x.is_checked).forEach((x) => {
            x.is_checked = false;
            if (!isEmptyData(x.linked_amount)) {
              x.unlinked_amount = x.unlinked_amount + x.linked_amount;
              this.linkBillUnlinked += x.linked_amount;
              x.linked_amount = null;
            }
          });
        } else {
          this.InvoiceBillList.forEach((x, index) => {
            if (this.BillSelectedRowKeys.includes(index)) {
              x.is_checked = true;
              if (isEmptyData(x.linked_amount)) {
                x.linked_amount = 0;
              }
            } else {
              x.is_checked = false;
            }
          });
        }
      },
      autoLinkBill(bill) {
        if (Math.abs(this.linkBillUnlinked) > Math.abs(bill.unlinked_amount)) {
          bill.linked_amount = round((parseFloat(bill.linked_amount) || 0) + bill.unlinked_amount, 2);
          this.linkBillUnlinked = round(this.linkBillUnlinked - bill.unlinked_amount, 2);
          bill.unlinked_amount = 0;
        } else {
          bill.linked_amount = round((parseFloat(bill.linked_amount) || 0) + this.linkBillUnlinked, 2);
          bill.unlinked_amount = round(bill.unlinked_amount - this.linkBillUnlinked, 2);
          this.linkBillUnlinked = 0;
        }
      },
      linkBillForInvoice() {
        const vm = this;
        if (vm.linkForm?.validate()) {
          vm.linkBillDialog = false;
          let bill_list = vm.InvoiceBillList.filter((b) => b.linked_amount).map((b) => ({
            id: b.id,
            linked_amount: b.linked_amount,
          }));
          const params = { invoice_id: vm.invoice.id, bill_list: bill_list };
          const doLink = function () {
            link_bill_for_invoice(params).then(() => {
              appStore.SET_ALERT_CONFIG(null);
              appStore.SET_WE_DIALOG(null);
              vm.doAfterEditInvoice('add');
            });
          };
          if (bill_list.length === 0) {
            vm.$showAlert('Warning', '确定不关联任何账单？', () => {
              doLink();
            });
          } else {
            vm.$showAlert('Warning', '确定关联这些账单？', () => {
              doLink();
            });
          }
        }
      },
      addInvoiceNumber(invoice) {
        const computeAmount = (tax_rate, amount) => {
          if (isNaN(Number(tax_rate)) || !amount) {
            return '';
          } else {
            return round(amount / (Number(tax_rate) / 100 + 1), 2);
          }
        };

        let inputs: DialogFormItem[] = [
          {
            type: 'text',
            label: '发票号',
            result: 'invoice_number',
            width: 24,
            rules: [required('string', '请输入发票号')],
          },
        ];
        if (invoice.is_china && this.isDetailPage && this.isDemandPage) {
          inputs = inputs.concat([
            {
              type: 'text',
              label: '含税金额',
              result: 'it_amount',
              width: 12,
              rules: [required('string', '请输入含税金额'), isNumber],
              change: (result) => {
                result.et_amount = computeAmount(result.tax_rate, result.it_amount);
              },
            },
            {
              type: 'text',
              label: '税率',
              result: 'tax_rate',
              width: 12,
              suffix: '%',
              rules: [required('string', '请输入税率'), isInt],
              change: (result) => {
                result.et_amount = computeAmount(result.tax_rate, result.it_amount);
              },
            },
            {
              type: 'text',
              label: '不含税金额',
              result: 'et_amount',
              width: 12,
              rules: [required('string', '请输入不含税金额'), isNumber],
            },
          ]);
        }
        this.$showDialog({
          title: '编辑发票号',
          width: '540px',
          init: invoice,
          labelCol: '120px',
          blocks: [{ type: 'input', value: inputs }],
          callback: (result) => {
            edit_invoice({ id: invoice.id, ...result }).then((res) => {
              appStore.SET_WE_DIALOG(null);
              // this.traceAction('Edit 发票号');
              this.doAfterEditInvoice(res);
            });
          },
        });
      },
      linkBillFiles(invoiceLink) {
        this.linkBillFilesDialog = true;
        this.fileMode = 'link';
        this.invoiceLinkId = invoiceLink.invoice_link_id;
        this.initLinkBillFiles();
      },
      sendBillFiles(invoiceLink) {
        this.linkBillFilesDialog = true;
        this.fileMode = 'send';
        this.invoiceLinkId = invoiceLink.invoice_link_id;
        this.initLinkBillFiles();
      },
      initLinkBillFiles() {
        this.BillFileSelectedRowKeys = [];
        get_link_file_list({ id: this.invoiceLinkId, mode: this.fileMode }).then((res) => {
          res.file_list.forEach((file, index) => {
            if (file.is_checked) {
              this.BillFileSelectedRowKeys.push(index);
            }
          });
          this.linkFiles = res.file_list;
        });
      },
      linkBillFilesCheckAllChange(val) {
        this.BillFileSelectedRowKeys = val;
        if (val.length === 0) {
          this.linkFiles.forEach((file) => {
            file.is_checked = false;
          });
        } else {
          this.linkFiles.forEach((file, index) => {
            if (this.BillFileSelectedRowKeys.includes(index)) {
              file.is_checked = true;
            } else {
              file.is_checked = false;
            }
          });
        }
      },
      linkBillFilesForInvoice() {
        const toLinkfiles = this.linkFiles.filter((file) => file.is_checked === true).map((file) => file.id);
        link_bill_files_for_invoice({
          files: toLinkfiles,
          id: this.invoiceLinkId,
          mode: this.fileMode,
        }).then((res) => {
          this.linkBillFilesDialog = false;
          this.doAfterEditInvoice(res);
        });
      },
      neutralizeInvoice(params) {
        const reasonList = this.appInfo.key_vals.neutralize_reason_list.split('\t').map((x) => ({ id: x, name: x }));
        this.$showDialog({
          width: '600px',
          labelCol: '80px',
          title: '提示',
          blocks: [
            {
              type: 'input',
              value: [
                {
                  type: 'radio',
                  label: '',
                  rules: [required('string', '请选择') as any],
                  result: 'reset_type',
                  items: [
                    { text: '直接重置', value: 1 },
                    { text: '先冲销，再重置', value: 2 },
                  ],
                  init: 1,
                  width: 24,
                },
                {
                  type: 'select',
                  label: '冲销原因',
                  rules: [required('string', '请选择') as any],
                  result: 'neutralize_reason',
                  items: reasonList,
                  show: (result) => result.reset_type != 1,
                  width: 24,
                },
              ],
            },
          ],
          callback: (result) => {
            appStore.SET_WE_DIALOG(null);
            if (result.reset_type === 2) {
              if (!this.invoice.sealBase64Data) {
                this.$showAlert('Warning', 'Please Upload Webeye Seal And Try Again!');
              } else {
                this.neutralizeInvoiceReason = result.neutralize_reason;
                this.showPrintInvoice = true;
              }
            } else {
              handle_flow_action({ ...params }).then((res) => {
                if (res.error_message) {
                  this.$showAlert('提示', res.error_message);
                } else {
                  appStore.SET_WE_DIALOG(null);
                  this.doAfterEditInvoice(res);
                }
              });
            }
          },
        });
      },
      async saveInvoicePDF() {
        const vm = this;
        const file = await generatorPDFByCanvas('printInvoice', 'invoice.pdf');
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function (e) {
          add_pdf({
            to_reopen: !isEmptyData(vm.neutralizeInvoiceReason),
            invoice_id: vm.invoice.id,
            pdf: e.target?.result,
          }).then((res) => {
            vm.doAfterEditInvoice(res);
            vm.showPrintInvoice = false;
            vm.neutralizeInvoiceReason = '';
          });
        };
      },
      assignToMe() {
        const originUser = this.invoice.flow!.current_state.owner.name;
        const tip = `Are you sure to assign this bill from ${originUser} to yourself?`;
        const params = { invoice_id: this.invoice.id };
        const callback = () => {
          assign_invoice_to_me(params).then((res) => {
            this.doAfterEditInvoice(res);
            appStore.SET_ALERT_CONFIG(null);
          });
        };

        this.$showAlert('Warning', tip, callback);
      },
    },
  });
